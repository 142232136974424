import { Button, Container, Group, Text, Timeline, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useClearStore } from "../../../store";
import { FaQuestionCircle } from "react-icons/fa";

export default function MerchantsHome() {
  const { t } = useTranslation();
  const clearStore = useClearStore();

  return (
    <Container fluid m={32}>
      <Title align="center" order={4} my={20}>
        {t("merchantsHome.title")}
        <div
          style={{
            width: "auto",
            height: 3,
            backgroundColor: "#b143ff",
            borderRadius: "10px",
            marginTop: "10px",
          }}
        />
      </Title>

      <Text size={"xs"} mt={12}>
        {t("merchantsHome.loggedMsg")}
      </Text>

      <Title order={4} my={20}>
        Comment ça marche ?
      </Title>

      <Timeline color="black" style={{ color: "black" }}>
        <Timeline.Item
          color="black"
          style={{ color: "black" }}
          title={t("merchantsHome.howItWorksStep1Title")}
        >
          <Text size={"sm"} mt={12} style={{ color: "black" }}>
            {t("merchantsHome.howItWorksStep1Description")}
          </Text>
        </Timeline.Item>
        <Timeline.Item
          color="black"
          style={{ color: "black" }}
          title={t("merchantsHome.howItWorksStep2Title")}
        >
          <Text size={"sm"} mt={12} style={{ color: "black" }}>
            {t("merchantsHome.howItWorksStep2Description")}
          </Text>
        </Timeline.Item>
        <Timeline.Item title={t("merchantsHome.howItWorksStep3Title")}>
          <Text size={"sm"} mt={12} style={{ color: "black" }}>
            {t("merchantsHome.howItWorksStep3Description")}
          </Text>
        </Timeline.Item>
        <Timeline.Item title={t("merchantsHome.howItWorksStep4Title")}>
          <Text size={"sm"} mt={12} style={{ color: "black" }}>
            {t("merchantsHome.howItWorksStep4Description")}
          </Text>
        </Timeline.Item>
        <Timeline.Item title={t("merchantsHome.howItWorksStep5Title")}>
          <Text size={"sm"} mt={12} style={{ color: "black" }}>
            {t("merchantsHome.howItWorksStep5Description")}
          </Text>
        </Timeline.Item>
        <Timeline.Item title={t("merchantsHome.howItWorksStep6Title")}>
          <Text size={"sm"} mt={12} style={{ color: "black" }}>
            {t("merchantsHome.howItWorksStep6Description")}
          </Text>
        </Timeline.Item>
        <Timeline.Item title={t("merchantsHome.howItWorksStep7Title")}>
          <Text size={"sm"} mt={12} style={{ color: "black" }}>
            {t("merchantsHome.howItWorksStep7Description")}
          </Text>
        </Timeline.Item>
      </Timeline>

      <Group style={{ marginTop: "38px" }}>
        <Text size={"sm"} style={{ color: "black" }} weight={700}>
          {t("merchantsHome.whyTransactionNotValidatedTitle")}
        </Text>
      </Group>

      <Text size={"sm"} mt={12} style={{ color: "black" }}>
        {t("merchantsHome.whyTransactionNotValidatedDescription")}
      </Text>

      <Group style={{ marginTop: "38px" }}>
        <FaQuestionCircle />
        <Text size={"sm"} style={{ color: "black" }} weight={700}>
          {t("merchantsHome.needHelpTitle")}
        </Text>
      </Group>

      <Text size={"sm"} mt={12} style={{ color: "black" }}>
        {t("merchantsHome.needHelpDescription")}
        <a href="mailto:marcsusinidlc@gmail.com">marcsusinidlc@gmail.com</a>
      </Text>

      <Text size={"sm"} mt={12} style={{ color: "black" }}>
        {t("merchantsHome.greetings")}
      </Text>
      <Button
        variant="light"
        color="blue"
        size="xs"
        onClick={clearStore}
        mt={50}
      >
        {t("common.signOut")}
      </Button>
    </Container>
  );
}
