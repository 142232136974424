import { useHttp } from "./http";
import { HttpMutators } from "./http-mutators";

export const useProductCategoryService = () => {
  const { get } = useHttp("/product-categories");

  return {
    getProductCategories: (mutators: HttpMutators, params?: any) =>
      get(mutators, { params }),
  };
};
