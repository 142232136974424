export default {
  common: {
    yes: "Oui",
    send: "Envoyer !",
    no: "Non",
    goBack: "Retour",
    ok: "Ok",
    signOut: "Se déconnecter",
    cancel: "Annuler",
    loading: "Chargement",
    EXTERNAL: "Externe",
    INTERNAL: "Interne",
    ADMIN: "Admin",
    caution: "Attention !",
    add: "Ajouter",
    update: "Mettre à jour",
    select: "Sélectionner",
    disable: "Désactiver",
    enable: "Activer",
    disabled: "Désactivé",
    enabled: "Activé",
    error: {
      anErrorOccurredPleaseRetryLater:
        "Une erreur est survenue. Veuillez réessayer plus tard.",
    },
  },
  signIn: {
    signYou: "Connectez-vous !",
    signIn: "Se connecter",
    signInExplanation: "Entrez votre identifiant et mot de passe",
    welcome: "Bienvenue",
    continueWithMicrosoft: "Continuer avec Microsoft",
    continue: "Continuer",
    email: "Email",
    emailPlaceholder: "email@address.com",
    password: "Mot de passe",
    forgotPassword: "Mot de passe oublié ?",
    or: "ou",
  },
  changeTemporaryPassword: {
    changeTemporaryPassword: "Changement du mot de passe temporaire",
    oldPassword: "Ancien mot de passe",
    newPassword: "Nouveau mot de passe",
    confirmPassword: "Confirmation du mot de passe",
    submit: "Valider",
    bitwardenSuggestion:
      "Pour garantir un bon niveau de sécurité, nous vous invitons à utiliser un gestionnaire de mots de passe tel que <linkText>Bitwarden</linkText>",
  },
  forgotPassword: {
    forgotPassword: "Mot de passe oublié ?",
    forgotPasswordExplanation:
      "Entrez votre email pour reçevoir un lien de rénitialisation",
    emailLabel: "Email",
    email: "Email",
    emailPlaceholder: "email@address.com",
    submit: "Valider",
    cancel: "Annuler",
  },
  home: {
    welcomeMessage: "Ravis de te revoir",
  },
  entryForm: {
    home: "Accueil",
    modalSubmitTitle: "Attention",
    modalSubmitDescriptionConfirmation: "Es-tu sûr(e) de confirmer ta saisie ?",
    modalSubmitDescriptionWeekend:
      "Ta saisie indique que tu as travaillé pendant le weekend.\nVeux-tu quand même l'envoyer ?",
    modalSubmitDescriptionOverwork:
      "Tu as saisi plus de jours que le nombre de jours ouvrés. \nVeux-tu quand même l'envoyer ?",
    modalSubmitDescriptionMissionIncomplete:
      "Tu dois saisir au moins une mission",
    modalSubmitDescriptionNoMissions: "Tu dois saisir au moins une mission",
    nature: "Typologie",
    refusedAlertTitle: "Ton CRA a été refusé !",
    adminRefusedAlertTitle: "Ce CRA a précédemment été refusé !",
    submit: "Envoyer",
    ok: "Ok",
    comment: "Commentaire",
    commentPlaceholder: "Laisse un message à ton administrateur ici !",
    craWaitingForValidation:
      "Tu as bien soumis ton CRA, il est en attente de validation",
    craValidated: "CRA validé !",
    recap: "Récapitulatif",
    totalDaysWorked_one:
      "{{count}} jour travaillé au total sur {{businessDays}} jours ouvrables",
    totalDaysWorked_other:
      "{{count}} jours travaillés au total sur {{businessDays}} jours ouvrables",
    daysWorkedFor_one: "{{count}} jour travaillé pour {{customer}}",
    daysWorkedFor_other: "{{count}} jours travaillés pour {{customer}}",
    days_one: "{{count}} jour",
    days_other: "{{count}} jours",
    noMission: "Aucune mission",
    previewCra: "Relire CRA",
    noMissionForUser: "{{user}} n'a aucune mission ce mois-ci",
    addMissions: "Ajouter des missions",
    externalNote:
      "<normalText>Si tu vois qu'il te manque des informations, n'hésite pas à contacter l'admin à l'adresse <linkText>admin-bo@reboot-conseil.com</linkText>.</normalText>",
    waitForAdmin:
      "Oups, il semblerait que tes missions n'aient pas été renseignées.",
  },
  calendar: {
    monthYear:
      "<strong>{{monthYear}}</strong> - {{businessDays}} jours ouvrables",
    customer: "Client",
    projects: "Projets",
    project: "Projet",
    name: "Nom",
    role: "Rôle",
    filterProject: "Filtrer par projet",
    modalClearValuesTitle: "Êtes-tu sûr ?",
    modalClearValuesDescription: "Les changements actuels seront écrasés",
    modalRemoveProjectDescription:
      "Toutes les données liées à ce projet seront éffacées",
    modalProjectNotFulfilledTitle: "Action impossible",
    modalProjectNotFulfilledDescription:
      "Veuillez remplir tous les champs du projet précédent avant d'en ajouter un autre",
    fillMonth: "Remplir le mois",
    week: "S",
    monthYearLabel: "{{monthYearLabel}} - {{businessDays}} jours ouvrables",
    comment: "Commentaire...",
    show: "Afficher",
    addMission: "Ajouter une mission",
    addElement: 'Ajouter "{{element}}"',
  },
  submission: {
    craDisplayOptions: "Options d'affichage du CRA",
    addImageToCustomer: "Ajouter une image à ce client pour l'afficher",
    totalDays_one: "Total: {{count}} j",
    totalDays_other: "Total: {{count}} jours",
    seperateCRA: "Séparer les CRA pour chaque consultant",
    showCategory: "Typologie de projet",
    showConsultantsSignature: "Espace signature consultant",
    consultantSignature: "Signature des consultants",
    showRecipientSignature: "Espace signature client",
    recipientSignature: "Signature du destinataire",
    showComment: "Espace commentaire",
    showProverb: "Dicton du jour",
    noMissionFound: "Aucune mission trouvée",
    export: "Exporter",
    generate: "Générer",
    generation: "Génération",
    projects: "Projets",
    project: "Projet",
    downloading: "Téléchargement",
    craPreview: "Aperçu du CRA",
    generatedDocuments: "Documents générés",
    nothingToReportHere: "Rien à signaler ici",
    dayLetter: "{{count}} J",
    submission: "Soumission",
    customers: "Clients",
    perCustomer: "Par client",
    perProject: "Par projet",
    chooseAProject: "Choisir un projet",
    chooseACustomer: "Choisir un client",
    customer: "Client",
    period: "Période",
    configuration: "Configuration",
    comment: "Commentaire",
    craType: "Type de CRA",
  },
  registerPartner: {
    addPartner: "Ajouter un partenaire",
    email: "Email",
    emailPlaceholder: "email@address.com",
    cancel: "Annuler",
    add: "Ajouter",
    firstName: "Prénom",
    lastName: "Nom",
    firstNamePlaceholder: "Auguste",
    lastNamePlaceholder: "Manou",
  },
  validation: {
    name: "Nom",
    date: "Date",
    creationDate: "Date de création",
    period: "Période",
    totalInDays: "Total (en jours)",
    status: "Status",
    validate: "Valider",
    refuse: "Refuser",
    readOnly: "Lecture seule",
    review: "Examiner",
    IN_PROGRESS: "En cours",
    PENDING: "À valider",
    REFUSED: "Refusé",
    VALIDATED: "Validé",
    noCraToValidate: "Aucun CRA à valider",
    cantRetrieveCras: "Impossible de récupérer les CRAs",
    craOf: "CRA de {{user}}",
    crasOfMonth: "CRAs des utilisateurs",
    addCra: "Ajouer un CRA",
  },
  admin: {
    craManagement: "Gestion des CRA",
    validation: "Validation",
    submission: "Soumission",
    general: "Général",
    management: "Gestion",
    customers: "Clients",
    users: "Utilisateurs",
    invoicing: "Facturation",
    toggleColorScheme: "Changer le thème",
    confirmEnableMaintenanceMode:
      "Êtes-vous sûr(e) de vouloir activer le mode maintenance ?",
    confirmDisableMaintenanceMode:
      "Êtes-vous sûr(e) de vouloir désactiver le mode maintenance ?",
    enableMaintenanceDescription:
      "Ceci retirera l'accès à Crakotte pour tous les utilisateurs",
    disableMaintenanceDescription:
      "Ceci rétablira l'accès à Crakotte pour tous les utilisateurs",
    maintenanceMode: "Mode maintenance",
  },
  customers: {
    customers: "Clients",
    addACustomer: "Ajouter un client",
    editCustomer: "Modifier le client {{customer}}",
    name: "Nom",
    logo: "Logo",
    contactEmail: "Email de contact",
    imageLimit: "L'image ne doit pas dépasser 5 Mo",
    projects: "Projets",
    addProject: 'Ajouter le projet "{{name}}"',
    typeToAddProject: "Écrivez pour ajouter un project",
    enterToAdd: "Tappez sur la toucher Enter pour ajouter",
    noCustomersFound: "Aucun client trouvé",
    noEmail: "Aucun email",
    modalConfirmDeleteTitle:
      "Êtes-vous sûr(e) de vouloir supprimer le client '{{clientName}}' ? Cette action est irréversible.",
    customerDeletion: "Suppression d'un client",
    cantDeleteCustomer: "Impossible de supprimer le client",
  },
  users: {
    users: "Users",
    userManagement: "Gestion des utilisateurs",
    roles: "Rôles",
    add: "Ajouter",
    addAUser: "Ajouter un utilisateur",
    editUser: "Modifier l'utilisateur {{user}}",
    searchUsers: "Rechercher des utilisateurs",
    firstName: "Prénom",
    lastName: "Nom",
    email: "Adresse email",
    role: "Rôle",
    firstNamePlaceholder: "Antoine",
    lastNamePlaceholder: "Durand",
    emailPlaceholder: "antoine.durand@gmail.com",
    coulndNotRetrieveUsers: "Impossible de récupérer les utilisateurs",
    couldNotAddUser: "Impossible d'ajouter l'utilisateur",
    name: "Nom",
    configureMissions: "Missions de {{user}} en {{period}}",
    emailSent: "Email envoyé",
  },
  maintenance: {
    description:
      "Notre équipe a besoin de faire quelques réglages, mais pas d'inquiétudes ils vont assurer 😃",
  },
  form: {
    passwordRules:
      "Le mot de passe doit contenir au moins 6 caractères dont une majuscule, une minuscule et un chiffre.",
    includesAtLeast6Characters: "Contient au moins 6 caractères",
    includesNumber: "Contient un chiffre",
    includesLowercaseLetter: "Contient une lettre minuscule",
    includesUppercaseLetter: "Contient une lettre majuscule",
    includesSpecialSymbol: "Contient un caractère spécial",
    error: {
      required: "Requis",
      invalidEmail: "Email invalide",
      passwordsDontMatch: "Les mots de passe ne correspondent pas",
    },
  },
  merchantsVerifyAccount: {
    title: "Finaliser votre inscription",
    merchant: "Commerçant",
    merchant_plural: "Commerçants",
    successMsg:
      "Votre compte a été validé avec succès ! Redirection vers la page de connexion...",
  },
  merchantsHome: {
    title: "Espace commerçant",
    loggedMsg:
      "Bienvenue sur Generation, vous êtes actuellement connecté(e) en tant que commerçant sur cet appareil",
    /*  */
    howItWorksStep1Title:
      "Référencement de votre produit sur l'application mobile Generation",
    howItWorksStep1Description:
      "Votre produit est ajouté à la liste des produits partenaires disponibles sur l'application mobile Generation. Cette opération est réalisée par un administrateur de Generation. Votre produit est alors disponible à l'achat pour les clients Generation contre un certain nombre de points et un montant en euros.",
    /*  */
    howItWorksStep2Title:
      "Achat de votre produit sur l'application mobile Generation",
    howItWorksStep2Description:
      "Un client achète ce produit sur l'application mobile Generation (contre un certain nombre de points) et obtient un QR code. Son achat ne sera pas validé tant que vous n'aurez pas scanné ce QR code et reçu le paiement en euros restant.",
    /*  */
    howItWorksStep3Title: "Le client se présente dans votre boutique",
    howItWorksStep3Description:
      "Le client se présente dans votre boutique et vous présente ce QR code.",
    /*  */
    howItWorksStep4Title: "Scan du QR code",
    howItWorksStep4Description:
      "Vous scannez le QR code avec un appareil doté d'un lecteur de QR code et d'un accès à internet.",

    /*  */
    howItWorksStep5Title: "Redirection vers le site web Generation",
    howItWorksStep5Description:
      "Vous êtes redirigé(e) vers la page de validation de la transaction. Si vous n'êtes pas connecté(e), la page de connexion s'affiche et vous devez vous connecter.",
    /*  */
    howItWorksStep6Title: "Informations de la transaction",
    howItWorksStep6Description:
      "Le détail de la transaction s'affichent. Vous pouvez alors valider la transaction en appuyant sur le bouton 'VALIDER'.",
    /*  */
    howItWorksStep7Title: "Validation de la transaction",
    howItWorksStep7Description:
      "Si la transaction est validée par notre système, le client vous paie le montant correspondant en euros et obtient son produit ! Vous recevez alors un email de confirmation de la transaction.",

    whyTransactionNotValidatedTitle:
      "Pourquoi la transaction n'a pas été validée ?",
    whyTransactionNotValidatedDescription:
      "Les raisons principales sont : le QR code a déjà été utilisé, vous n'êtes pas autorisé(e) à valider cette transaction, le QR code ne correspond pas à un produit de votre boutique ou le QR code est invalide. En cas de doute, n'hésitez pas à nous contacter pour plus d'informations.",
    needHelpTitle: "Besoin d'aide ?",
    needHelpDescription:
      " Si vous rencontrez des difficultés, n'hésitez pas à nous contacter à l'adresse suivante : ",

    greetings: "Merci de votre confiance !",
  },
};
