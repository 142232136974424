import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Group,
  Loader,
  Menu,
  Modal,
  Paper,
  Table,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { IoIosRemoveCircleOutline, IoIosSearch } from "react-icons/io";
import { Partner, PartnerStatus } from "../../types";
import { BsPencil, BsPlus, BsThreeDots } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePartnerService } from "../../services/partner";

type CreatePartnerPayload = {
  name: string;
  userEmails: string[];
  newUserEmails: string[];
};

const CreatePartnerValidationSchema = Yup.object().shape({
  name: Yup.string().required("Le nom du partenaire est requis"),
  userEmails: Yup.array().of(Yup.string().email("Email invalide")),
  newUserEmails: Yup.array().of(Yup.string().email("Email invalide")),
});

export default function Partners() {
  const { classes } = useStyles();
  const {
    getPartners,
    createPartner,
    updatePartner,
    updateStatusPartner,
    removeUserFromPartner,
  } = usePartnerService();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [partners, setPartners] = useState<Partner[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<Partner>();
  const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [showUpdateStatusPartnerModal, setShowUpdateStatusPartnerModal] =
    useState<boolean>(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState<{
    display: boolean;
    indexToRemove: string;
  }>({
    display: false,
    indexToRemove: "",
  });

  useEffect(() => {
    getPartners(
      {
        error: console.error,
        loading: setLoading,
        success: (res) => {
          setPartners(res);
        },
      },
      {
        text: searchText,
      }
    );
  }, [searchText]);

  useEffect(() => {
    if (selectedPartner) {
      formik.setValues({
        name: selectedPartner.name,
        userEmails: selectedPartner.users.map((user) => user.email),
        newUserEmails: [],
      });
    }
  }, [selectedPartner]);

  const formik = useFormik<CreatePartnerPayload>({
    initialValues: {
      name: "",
      userEmails: [],
      newUserEmails: [],
    },
    validationSchema: CreatePartnerValidationSchema,
    onSubmit: (values) => {
      setError(null);
      if (selectedPartner) {
        updatePartner(
          {
            error: (err) => setError(err?.message || "Une erreur est survenue"),
            loading: setSaveLoading,
            success: (res) => {
              const updatedPartners = partners.map((p) =>
                p._id === res._id ? { ...res } : p
              );
              setPartners(updatedPartners);
              formik.resetForm();
              setShowPartnerModal(false);
            },
          },
          selectedPartner._id,
          {
            name: values.name,
            emails: values.newUserEmails,
          }
        );
      } else {
        createPartner(
          {
            error: (err) => setError(err?.message || "Une erreur est survenue"),
            loading: setSaveLoading,
            success: (res) => {
              setPartners([...partners, res]);
              formik.resetForm();
              setShowPartnerModal(false);
            },
          },
          {
            name: values.name,
            emails: values.newUserEmails,
          }
        );
      }
    },
  });

  const handleUpdateStatusPartner = async (
    partnerId: string,
    partnerStatus: PartnerStatus
  ) => {
    updateStatusPartner(
      {
        error: console.error,
        loading: setSaveLoading,
        success: (res) => {
          const updatedPartners = partners.map((p) =>
            p._id === partnerId ? { ...res } : p
          );
          setPartners(updatedPartners);
        },
      },
      partnerId,
      {
        status:
          partnerStatus === PartnerStatus.ENABLED
            ? PartnerStatus.DISABLED
            : PartnerStatus.ENABLED,
      }
    );
  };

  const handleRemoveUserFromPartner = async (
    partnerId: string,
    userId: string
  ) => {
    removeUserFromPartner(
      {
        error: console.error,
        loading: setSaveLoading,
        success: (res) => {
          const updatedPartners = partners.map((p) =>
            p._id === partnerId ? { ...res } : p
          );
          setPartners(updatedPartners);
          setSelectedPartner(res);
        },
      },
      partnerId,
      userId
    );
  };

  const selectedPartnerIsEnabled =
    selectedPartner?.status === PartnerStatus.ENABLED;

  const rows = partners.map((partner) => (
    <tr key={partner._id}>
      <td>{partner.name}</td>
      <td>{partner.users.length}</td>
      <td>
        <Badge
          size="sm"
          color={partner.status === PartnerStatus.ENABLED ? "green" : "gray"}
        >
          {partner.status === PartnerStatus.ENABLED ? "Actif" : "Désactivé"}
        </Badge>
      </td>

      <td>
        <Group>
          <ActionIcon
            onClick={() => {
              setSelectedPartner(partner);
              setShowPartnerModal(true);
            }}
          >
            <BsPencil size={12} color="black" />
          </ActionIcon>

          <Menu shadow="md" offset={0}>
            <Menu.Target>
              <ActionIcon variant="transparent">
                <BsThreeDots size={12} color="black" />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={
                  <FiX
                    size={16}
                    color={
                      partner.status === PartnerStatus.ENABLED ? "red" : "gray"
                    }
                  />
                }
                onClick={() => {
                  setSelectedPartner(partner);
                  setShowUpdateStatusPartnerModal(true);
                }}
              >
                <Text
                  style={{
                    color:
                      partner.status === PartnerStatus.ENABLED ? "red" : "gray",
                  }}
                >
                  {partner.status === PartnerStatus.ENABLED
                    ? `Désactiver`
                    : `Réactiver`}{" "}
                  ce partenaire
                </Text>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div className={classes.rootContainer}>
      {/* title */}
      <Title order={2}>Partenaires</Title>
      <Group position="right">
        <Button
          onClick={() => {
            setShowPartnerModal(true);
          }}
        >
          Nouveau
        </Button>
      </Group>

      {/* body */}
      <Paper
        p="xl"
        shadow="xs"
        style={{
          borderRadius: 10,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#EDF0F2",
          backgroundColor: "white",
        }}
      >
        {/* search input */}
        <TextInput
          placeholder="Recherche par nom"
          icon={<IoIosSearch size={14} />}
          onChange={(event) => setSearchText(event.currentTarget.value)}
        />

        {/* table */}
        {loading ? (
          <Center m="xl">
            <Loader />
          </Center>
        ) : (
          <Table mt={"xl"}>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Comptes associés</th>
                <th>Statut</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        )}
      </Paper>

      {/* Modal Partner */}
      <Modal
        opened={showPartnerModal}
        onClose={() => {
          setSelectedPartner(undefined);
          setShowPartnerModal(false);
          formik.resetForm();
        }}
        size="md"
        title={
          <Title order={4}>
            {selectedPartner ? "Fiche partenaire" : "Nouveau partenaire"}
          </Title>
        }
        withCloseButton={true}
        styles={{
          modal: { minWidth: 600 },
          title: { padding: 10, paddingTop: 0 },
          body: { padding: 10 },
        }}
        overflow="outside"
      >
        <form>
          <Text style={{ color: "black" }} mb="8px">
            Nom du partenaire
          </Text>
          <TextInput
            id="name"
            placeholder="Nom du partenaire"
            style={{ flex: 1 }}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={!!formik.touched.name && !!formik.errors.name}
          />

          <Text style={{ color: "black", marginTop: "10px" }}>
            Comptes associés
          </Text>
          {formik.values.userEmails.map((email, index) => (
            <Group key={index}>
              <TextInput
                my={10}
                style={{ flex: 1 }}
                placeholder="Email du compte associé"
                value={formik.values.userEmails[index]}
                error={
                  !!formik.touched.userEmails &&
                  !!formik.errors.userEmails &&
                  !!formik.errors.userEmails[index]
                }
                disabled={true}
              />
              <ActionIcon
                disabled={saveLoading}
                onClick={async () => {
                  setShowRemoveUserModal({
                    display: true,
                    indexToRemove: selectedPartner?.users[index]._id!,
                  });
                }}
              >
                <IoIosRemoveCircleOutline size={16} color={"red"} />
              </ActionIcon>
            </Group>
          ))}
          {formik.values.newUserEmails.map((email, index) => (
            <Group key={index}>
              <TextInput
                required
                id={`email-${index}`}
                my={10}
                style={{ flex: 1 }}
                placeholder="Nouvel email"
                value={formik.values.newUserEmails[index]}
                error={
                  !!formik.touched.newUserEmails &&
                  !!formik.errors.newUserEmails &&
                  !!formik.errors.newUserEmails[index]
                }
                onChange={(event) => {
                  const newUserEmailsCopy = [...formik.values.newUserEmails];
                  newUserEmailsCopy[index] = event.currentTarget.value;
                  formik.setFieldValue("newUserEmails", newUserEmailsCopy);
                }}
              />
              <ActionIcon
                onClick={() => {
                  const newUserEmailsCopy = [...formik.values.newUserEmails];
                  newUserEmailsCopy.splice(index, 1);
                  formik.setFieldValue("newUserEmails", newUserEmailsCopy);
                }}
              >
                <FiX size={16} />
              </ActionIcon>
            </Group>
          ))}
          {/* Plus icon to add email account */}
          <Button
            variant="outline"
            my={10}
            style={{ borderStyle: "dashed" }}
            onClick={() => {
              formik.setFieldValue("newUserEmails", [
                ...formik.values.newUserEmails,
                "",
              ]);
            }}
          >
            <BsPlus size={26} />
            Ajouter un compte
          </Button>
          <Text size="xs">
            Chaque <strong>nouveau</strong> compte associé recevra un email
            d&apos;invitation contenant les instructions pour activer son compte
            et pouvoir scanner les produits associés.
          </Text>
          {/* action buttons */}
          <Group position="right" mt="xl">
            <Button
              variant="outline"
              onClick={() => {
                setSelectedPartner(undefined);
                setShowPartnerModal(false);
                formik.resetForm();
              }}
            >
              Annuler
            </Button>
            <Button onClick={() => formik.handleSubmit()} loading={saveLoading}>
              Sauvegarder
            </Button>
          </Group>
          {error && <Text style={{ color: "red" }}>{error}</Text>}
        </form>
      </Modal>

      {/* Disable partner modal */}
      <Modal
        opened={showUpdateStatusPartnerModal}
        onClose={() => setShowUpdateStatusPartnerModal(false)}
        size="sm"
        title={
          <Title order={4}>
            {selectedPartnerIsEnabled ? "Désactiver" : "Activer"} le partenaire
          </Title>
        }
        withCloseButton={true}
        styles={{
          modal: { minWidth: 600 },
          title: { padding: 10, paddingTop: 0 },
          body: { padding: 10 },
        }}
        overflow="outside"
      >
        <Text style={{ color: "black" }}>
          Êtes-vous sûr de vouloir{" "}
          {selectedPartnerIsEnabled ? "désactiver" : "activer"} le partenaire{" "}
          <strong>{selectedPartner?.name}</strong> ?
          <br />
          <Text size={"xs"}>
            {selectedPartnerIsEnabled
              ? `Si vous désactivez ce partenaire, les produits associés ne pourront
            plus être scannés.`
              : `Si vous activez ce partenaire, les produits associés pourront de nouveau être scannés.`}
          </Text>
        </Text>
        <Group position="right" mt="xl">
          <Button
            variant="outline"
            onClick={() => {
              setSelectedPartner(undefined);
              setShowUpdateStatusPartnerModal(false);
            }}
          >
            Annuler
          </Button>
          <Button
            color="red"
            disabled={saveLoading}
            onClick={async () => {
              if (selectedPartner) {
                await handleUpdateStatusPartner(
                  selectedPartner._id,
                  selectedPartner.status
                );
              }
              setSelectedPartner(undefined);
              setShowUpdateStatusPartnerModal(false);
            }}
          >
            {selectedPartnerIsEnabled ? "Désactiver" : "Activer"}
          </Button>
        </Group>
      </Modal>

      {/* Remove user from partner modal */}
      <Modal
        opened={showRemoveUserModal.display}
        onClose={() =>
          setShowRemoveUserModal({ display: false, indexToRemove: "" })
        }
        size="sm"
        title={<Title order={4}>Supprimer un compte associé</Title>}
        withCloseButton={true}
        styles={{
          modal: { minWidth: 600 },
          title: { padding: 10, paddingTop: 0 },
          body: { padding: 10 },
        }}
        overflow="outside"
      >
        <Text style={{ color: "black" }}>
          Êtes-vous sûr de vouloir supprimer ce compte ?
          <br />
          <Text size={"xs"}>
            Si vous supprimez ce compte, il ne pourra plus scanner les produits
            associés à ce partenaire.
          </Text>
        </Text>
        <Group position="right" mt="xl">
          <Button
            variant="outline"
            onClick={() =>
              setShowRemoveUserModal({
                display: false,
                indexToRemove: "",
              })
            }
          >
            Annuler
          </Button>
          <Button
            color="red"
            disabled={saveLoading}
            onClick={async () => {
              if (selectedPartner) {
                await handleRemoveUserFromPartner(
                  selectedPartner._id,
                  showRemoveUserModal.indexToRemove
                );
              }
              setShowRemoveUserModal({
                display: false,
                indexToRemove: "",
              });
            }}
          >
            Supprimer
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  rootContainer: {
    display: "flex",
    marginLeft: "35vh",
    flexDirection: "column",
    height: "100%",
    padding: 40,
    paddingTop: 20,
    gap: 20,
  },
}));
