import { PartnerStatus } from "../types";
import { useHttp } from "./http";
import { HttpMutators } from "./http-mutators";

export const usePartnerService = () => {
  const { get, post, patch } = useHttp("/partners");

  return {
    getPartners: (mutators: HttpMutators, params?: any) =>
      get(mutators, {
        headers: { "Content-Type": "application/json" },
        params,
      }),
    createPartner: (mutators: HttpMutators, payload: any) =>
      post(mutators, {
        headers: { "Content-Type": "application/json" },
        payload,
      }),

    updatePartner: (mutators: HttpMutators, id: string, payload: any) =>
      patch(mutators, {
        url: `/${id}`,
        headers: { "Content-Type": "application/json" },
        payload,
      }),
    updateStatusPartner: (
      mutators: HttpMutators,
      id: string,
      payload: {
        status: PartnerStatus;
      }
    ) =>
      patch(mutators, {
        url: `/${id}/status`,
        headers: { "Content-Type": "application/json" },
        payload,
      }),

    removeUserFromPartner: (
      mutators: HttpMutators,
      id: string,
      userId: string
    ) =>
      patch(mutators, {
        url: `/${id}/remove-user`,
        headers: { "Content-Type": "application/json" },
        payload: {
          userId,
        },
      }),
  };
};
