export default {
  common: {
    placeAnAd: "Place an ad",
    signIn: "Sign In",
    signUp: "Sign Up",
    signOut: "Sign Out",
  },
  signIn: {
    welcome: "Bienvenue",
    continueWithMicrosoft: "Continuer avec Microsoft",
    continue: "Continuer",
    email: "Email",
    emailPlaceholder: "email@address.com",
    password: "Mot de passe",
    forgotPassword: "Mot de passe oublié ?",
    or: "ou",
    signIn: "Bienvenue !",
  },
  home: {
    welcomeMessage: "Hello",
  },
  changeTemporaryPassword: {
    changeTemporaryPassword: "Changement du mot de passe temporaire",
    oldPassword: "Ancien mot de passe",
    newPassword: "Nouveau mot de passe",
    confirmPassword: "Confirmation du mot de passe",
    submit: "Valider",
    bitwardenSuggestion:
      "Pour garantir un bon niveau de sécurité, nous vous invitons à utiliser un gestionnaire de mots de passe tel que <linkText>Bitwarden</linkText>",
  },
  forgotPassword: {
    emailLabel: "Email",
    email: "Email",
    emailPlaceholder: "email@address.com",
    submit: "Valider",
    cancel: "Annuler",
    forgotPassword: "Mot de passe oublié ?",
  },
  user: {
    search: {
      filters: "Filters",
      clearAll: "Clear All",
      search: "Search",
      categories: "Categories",
      allCategories: "All categories",
      results: "Results",
    },
    menu: {
      messages: "Messages",
      favorites: "Favourites",
      track: "Track transactions",
      history: "History",
      settings: "Settings",
    },
    ad: {
      buyWith: "Buy with",
      safeDeal: "Safe deal",
      learnMore: "Learn more",
      delivery: "Delivery available",
      howItWorks: "How does it work?",
      handDelivery: "Hand delivery available",
      cardPaiement: "Credit card paiement alternative",
      sendMessageToSeller: "Send message to the seller",
      reportAd: "Report ad",
      rightAndObligations: "Your rights and obligations",
    },
    message: {
      warningMessage:
        "DO NEVER, UNDER ANY CIRCUMSTANCES, SHARE YOUR PRIVATE KEY OR SEED PHRASE!",
    },
  },
  maintenance: {
    description:
      "Notre équipe a besoin de faire quelques réglages, mais pas d'inquiétudes ils vont assurer 😃",
  },
  form: {
    passwordRules:
      "Le mot de passe doit contenir au moins 6 caractères dont une majuscule, une minuscule et un chiffre",
    includesAtLeast6Characters: "Contient au moins 6 caractères",
    includesNumber: "Contient un chiffre",
    includesLowercaseLetter: "Contient une lettre minuscule",
    includesUppercaseLetter: "Contient une lettre majuscule",
    includesSpecialSymbol: "Contient un caractère spécial",
    error: {
      required: "Requis",
      invalidEmail: "Email invalide",
      passwordsDontMatch: "Les mots de passe ne correspondent pas",
    },
  },
  merchantsVerifyAccount: {
    title: "Complete your registration",
    merchant: "Merchant",
    merchant_plural: "Merchants",
    successMsg: "Your account has been successfully verified",
  },
  merchantsHome: {
    title: "Merchant Home",
    loggedMessage:
      "Welcome to Generation, you are logged in as a merchant on this device",
    howItWorksStep1: "1) Create your ad",
  },
};
