import {
  Alert,
  Button,
  Center,
  Divider,
  Group,
  Paper,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { HiMail } from "react-icons/hi";
import * as Yup from "yup";
import { CustomPasswordInput } from "../../common";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CgDanger } from "react-icons/cg";
import { useAuthService } from "../../../services";
import { VerifiyAccountMerchantPayload } from "../../../services/authentication";

export default function MerchantsVerifyAccount() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { verifyAccountMerchant } = useAuthService();
  const [loading, setLoading] = useState(false);
  const [searchParams, _] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [success, setSuccess] = useState(false);

  const formik = useFormik<VerifiyAccountMerchantPayload>({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t("form.error.invalidEmail"))
        .required(t("form.error.required")),
      firstName: Yup.string().required(t("form.error.required")),
      lastName: Yup.string().required(t("form.error.required")),
      // TODO extract YupPasswordRule logic because not done in the original code (code only was only doing backend validation)
      newPassword: Yup.string()
        .required(t("form.error.required"))
        .min(6, t("form.passwordRules"))
        .matches(/[A-Z]/, t("form.passwordRules"))
        .matches(/[a-z]/, t("form.passwordRules"))
        .matches(/[0-9]/, t("form.passwordRules")),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("newPassword"), null],
          t("form.error.passwordsDontMatch")
        )
        .required(t("form.error.required")),
    }),
    onSubmit: (values) => {
      verifyAccountMerchant(
        {
          loading: setLoading,
          error: (err) => {
            setErrorMessage(err.message);
          },
          success: () => {
            formik.resetForm();
            setSuccess(true);
            setTimeout(() => {
              navigate("/sign-in?email=" + values.email);
            }, 5000);
          },
        },
        {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          code: searchParams.get("code") || "",
        }
      );
    },
  });

  useEffect(() => {
    const email = searchParams.get("email");
    if (email) {
      formik.setFieldValue("email", email);
    }
  }, []);

  return (
    <>
      <Group position="center" align={"start"} pb={20} spacing={2}>
        <Title order={3} align="center">
          <div>Generation</div>
        </Title>
        <Title order={3} pl={5} align="center">
          {t("merchantsVerifyAccount.merchant")}
          <div
            style={{
              width: "auto",
              height: 7,
              backgroundColor: "#b143ff",
              borderRadius: "10px",
            }}
          />
        </Title>
      </Group>

      <Title order={6} align="center">
        <div>{t("merchantsVerifyAccount.title")}</div>
      </Title>
      <Divider />

      <Paper
        p="lg"
        shadow="xl"
        className={classes.formContainer}
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div>
          <Title order={6} className={classes.label}>
            {t("signIn.email")}
          </Title>
          <TextInput
            disabled
            id="email"
            type="email"
            icon={<HiMail />}
            aria-label={t("signIn.email")}
            aria-required="true"
            autoComplete="on"
            placeholder={t("signIn.emailPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={
              !!formik.touched.email &&
              !!formik.errors.email &&
              formik.errors.email
            }
          />
        </div>

        {/* Lastname */}
        <div>
          <Title order={6} className={classes.label}>
            {t("users.lastName")}
          </Title>
          <TextInput
            id="lastName"
            type="lastName"
            aria-label={t("users.lastName")}
            aria-required="true"
            autoComplete="on"
            placeholder={t("users.lastName")}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={
              !!formik.touched.lastName &&
              !!formik.errors.lastName &&
              formik.errors.lastName
            }
          />
        </div>

        {/* Firstname */}
        <div>
          <Title order={6} className={classes.label}>
            {t("users.firstName")}
          </Title>
          <TextInput
            id="firstName"
            type="firstName"
            aria-label={t("users.firstName")}
            aria-required="true"
            autoComplete="on"
            placeholder={t("users.firstName")}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            error={
              !!formik.touched.firstName &&
              !!formik.errors.firstName &&
              formik.errors.firstName
            }
          />
        </div>

        <div>
          <Title order={6} className={classes.label}>
            {t("changeTemporaryPassword.newPassword")}
          </Title>
          <CustomPasswordInput
            id="newPassword"
            name="newPassword"
            aria-label={t("signIn.password")}
            aria-required="true"
            placeholder={t("signIn.password")}
            autoComplete="on"
            onChange={formik.handleChange}
            value={formik.values.newPassword}
            error={
              !!formik.touched.newPassword &&
              !!formik.errors.newPassword &&
              formik.errors.newPassword
            }
            withRules
          />
        </div>

        <div>
          <Title order={6} className={classes.label}>
            {t("changeTemporaryPassword.confirmPassword")}
          </Title>
          <CustomPasswordInput
            id="confirmPassword"
            name="confirmPassword"
            aria-label={t("changeTemporaryPassword.confirmPassword")}
            aria-required="true"
            placeholder={t("changeTemporaryPassword.confirmPassword")}
            autoComplete="on"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            error={
              !!formik.touched.confirmPassword &&
              !!formik.errors.confirmPassword &&
              formik.errors.confirmPassword
            }
          />
        </div>

        <Button
          className="bg-orange-500 text-white"
          type="submit"
          loading={loading}
        >
          {t("signIn.signIn")}
        </Button>

        {errorMessage && (
          <Alert
            icon={<CgDanger />}
            sx={(theme) => ({
              boxShadow: theme.shadows.md,
              position: "relative",
            })}
            color="red"
            radius="sm"
            variant="filled"
          >
            {errorMessage}
          </Alert>
        )}
        {success && (
          <Alert
            icon={<CgDanger />}
            sx={(theme) => ({
              boxShadow: theme.shadows.md,
              position: "relative",
            })}
            color="green"
            radius="sm"
            variant="filled"
          >
            {t("merchantsVerifyAccount.successMsg")}
          </Alert>
        )}
      </Paper>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: theme.radius.md,
    backdropFilter: "blur(2px)",
    backgroundColor: "white",
    gap: theme.spacing.md,
    position: "relative",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#EAECEF",
  },
  label: {
    fontWeight: 600,
    marginBottom: 5,
  },
}));
