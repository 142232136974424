import {
  Alert,
  Button,
  Divider,
  Flex,
  Loader,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTransactionService } from "../../../services";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { Transaction } from "../../../types";

//TODO translate

export default function MerchantsValidateTx() {
  const [searchParams, _] = useSearchParams();
  const [transaction, setTransaction] = useState<Transaction>();
  const { classes } = useStyles();
  const { scanTransaction, getTransactionById } = useTransactionService();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    const txIdParam = searchParams.get("txId");
    if (txIdParam) {
      getTransactionById(
        {
          success: (data) => setTransaction(data),
          error: (err) => setErrorMessage(err.message),
        },
        txIdParam
      ).finally(() => setLoading(false));
    }
  }, [searchParams]);

  const handleScanTx = () => {
    setErrorMessage("");
    setSuccess(false);
    setLoading(true);
    // setTimeout just for UI
    setTimeout(() => {
      scanTransaction(
        {
          success: () => setSuccess(true),
          error: (err) =>
            // Temporary durty fix because backend does not send error message correctly (should be error code and message in the response body for translation purpose)
            setErrorMessage(
              err.message === "Transaction already scanned"
                ? "Cette transaction a déjà été validée"
                : "Une erreur est survenue"
            ),
        },
        transaction?._id!
      ).finally(() => setLoading(false));
    }, 2000);
  };

  return (
    <div className={classes.container}>
      <Title order={3} align="center" py={12}>
        Validation de la transaction
      </Title>
      {transaction?._id ? (
        <Flex direction="column" align="center" px={4}>
          <div className={classes.infosContainer}>
            <Text size={"sm"} py={4}>
              <Text weight={700}>Transaction id :</Text>
              {transaction._id}
            </Text>
            <Text size={"sm"} py={4}>
              <Text weight={700}>Produit :</Text>
              {transaction.product?.title}
            </Text>
            <Text size={"sm"} py={4}>
              <Text weight={700}>Points :</Text>
              {transaction.points}
            </Text>
            <Divider my={12} />
            <Text size={"lg"} align="center" weight={800}>
              {transaction.product?.price} €
            </Text>
          </div>
          {loading && (
            <>
              <Text>Vérification en cours ...</Text>
              <Loader color="#b143ff" variant="dots" size={"xl"} my={12} />
            </>
          )}

          <Button onClick={handleScanTx} bg="#b143ff" loading={loading} my={12}>
            Valider
          </Button>
          {errorMessage ? (
            <Alert
              icon={<FiAlertCircle size={16} />}
              title="Erreur"
              color="red"
            >
              {errorMessage}
            </Alert>
          ) : null}
          {success && (
            <Alert
              icon={<FiCheckCircle size={16} />}
              title="Succès"
              color="green"
            >
              Transaction validée
              <br />
              Un email récapitulatif a été envoyé à tous les membres de
              l&apos;équipe
            </Alert>
          )}
        </Flex>
      ) : (
        <>
          {loading && <Loader color="#b143ff" size={"xl"} my={12} />}
          {errorMessage && (
            <Alert
              icon={<FiAlertCircle size={16} />}
              title="Erreur"
              color="red"
            >
              Impossible de récupérer les données de la transaction
            </Alert>
          )}
        </>
      )}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  infosContainer: {
    border: `1px solid #e2e2e2`,
    padding: 16,
    borderRadius: theme.radius.sm,
    marginBottom: 16,
    backgroundColor: "##FFF",
  },
}));
