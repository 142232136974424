import { HttpMutators } from "./http-mutators";
import { useHttp } from "./http";
import { UserRoles } from "../types";

export type ChangePasswordPayload = {
  newPassword: string;
};

export const useUserService = () => {
  const { get, post, patch, remove } = useHttp("/users");

  return {
    getUsers: (mutators: HttpMutators, params?: any) =>
      get(mutators, { url: "/all", params }),
    getUsersByProperty: (
      mutators: HttpMutators,
      payload: any,
      params?: any
    ) => {
      // query params: sort=xp:-1 to sort by xp in descending order
      return post(mutators, { url: "/property?sort=xp:-1", payload, params });
    },
    getProfile: (mutators: HttpMutators) => get(mutators, { url: "/who-am-i" }),
    updateCurrentUser: (mutators: HttpMutators, payload: any) =>
      patch(mutators, { payload }),
    updateUser: (mutators: HttpMutators, id: string, payload: any) =>
      patch(mutators, { url: `/${id}`, payload }),
    deleteUser: (mutators: HttpMutators, id: string) =>
      remove(mutators, { url: `/${id}/delete` }),
  };
};
